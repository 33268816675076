import { ApiService } from "@zelando/vuelando";
import { Card } from "@/models/entities/card.interface";
import {
  PaymentIntentGetRequest,
  Receipt,
} from "@/models/entities/receipt.interfece";
import { Downgrade } from "@/models/entities/downgrade.interface";
import { UpgradePayment } from "@/models/entities/upgrade-payment";
import { UpgradeSubYear } from "@/models/entities/upgrade-sub-year.interface";

const controller = "payments";

class PaymentsService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetPaymentMethod() {
    return this.getAll<Card[]>(`${controller}/paymentmethods`);
  }

  GetAllReceipt(req: PaymentIntentGetRequest) {
    return this.getAll<Receipt[]>(`${controller}/getallreceipt`, {
      params: req,
    });
  }

  CreateSession(payment: UpgradePayment) {
    return this.post<UpgradePayment, string>(
      `${controller}/createsession`,
      payment
    );
  }

  SaveSubscription() {
    return this.getAll<void>(`${controller}/savesubscription`);
  }

  UpdateSubscriptionYear(subscription: UpgradeSubYear) {
    return this.post<UpgradeSubYear, void>(
      `${controller}/upgradesubscriptionyear`,
      subscription
    );
  }
  CancelSubscription() {
    return this.getAll<void>(`${controller}/cancelsubscription`);
  }
  GetChangedPlan() {
    return this.getAll<boolean>(`${controller}/getchangedplan`);
  }
  UpdatePlan(downgrade: Downgrade) {
    return this.post<Downgrade, void>(`${controller}/updateplan`, downgrade);
  }

  RemainingDaysUp(priceId: string) {
    return this.getAll<string>(`${controller}/remainingdaysup/${priceId}`);
  }
}

export const paymentsService = new PaymentsService();
